@import 'styles/variables';
@import 'styles/mixins';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: $frankRuhlLibreFont, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

  #root {
    height: 100%;

    --toastify-color-success: #2d5452;
    --toastify-font-family: $globalFont;
    --toastify-icon-color-success: #2d5452;
    --toastify-color-progress-success: #2d5452;
  }
}

a {
  text-decoration: none;
  color: $primary-dark;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
